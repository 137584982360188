/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import Layout from './layout'
import SEO from './SEO/seo'
import { useLocation } from '@reach/router'
import ContactHero from './contactHero'
import ContactBody from './contactBody'
import ContactFooter from './contactFooter'

import { Fragment } from 'react'

const Contact = () => {
  const { pathname } = useLocation()
  return (
    <Fragment>
      <Layout location={pathname}>
        <SEO title="Get in touch" description="Need help with your project? Want to join our team? We'd love to hear from you!" />
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ContactHero />
          <ContactBody />
          {/* <ContactFooter /> */}
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default Contact
