/** @jsx jsx */
import { jsx, Flex, Box, Styled, useThemeUI } from 'theme-ui'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import { navigate } from 'gatsby-link'
import { animated, useSpring } from 'react-spring'
import { Waypoint } from 'react-waypoint'
import { useToggle } from '../hooks'
import { Form, Field } from 'react-final-form'
import Container from './container'
import { ButtonAnimated } from './animation'

const AnimatedFlex = animated(Flex)

const ContactFormGrid = () => {
  const { isToggled: active, toggle: setActive } = useToggle(false)
  const context = useThemeUI()
  const { theme } = context

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const formFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 250 : 0,
  })

  const CustomInput = styled.input`
    margin: 0px 0px;
    padding: 10px 10px;
    border: 1px solid ${theme.colors.formInputBorder};
    background: ${theme.colors.formInput};
    border-radius: 6px;
    font-size: 16px;
    ::placeholder {
      color: #a3a3a3;
      font-style: italic;
    }
  `

  const CustomTextArea = styled.textarea`
    margin: 0px 0px;
    padding: 10px 10px;
    border: 1px solid ${theme.colors.formInputBorder};
    background: ${theme.colors.formInput};
    border-radius: 6px;
    font-size: 16px;
    ::placeholder {
      color: #a3a3a3;
      font-style: italic;
    }
  `

  const CustomSpan = styled.span`
    margin: 0px;
    padding: 0px 0px 0px 10px;
    color: red;
  `

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const onSubmit = (values, e) => {
    // e.preventDefault() // This is handled by React Final Form
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'Contact',
        ...values,
      }),
    })
      .then(() => navigate('/contact/thank-you/'))
      .catch((error) => alert(error))
  }

  const required = (value) => (value ? undefined : 'is required')
  // const mustBeNumber = (value) => (isNaN(value) ? 'must be a number' : undefined)
  // const minValue = (min) => (value) => (isNaN(value.length) || value.length >= min ? undefined : `should be at least ${min} digits`)
  // const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <Fragment>
      <Waypoint
        bottomOffset="10%"
        onEnter={() => {
          if (!active) setActive(true)
        }}
      />
      <AnimatedFlex style={headingFade} sx={{ mb: 3, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ mx: 2, textAlign: 'center' }}>
          <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}>Please complete the form below</Styled.p>
        </Box>
      </AnimatedFlex>
      <AnimatedFlex
        style={formFade}
        sx={{
          bg: theme.colors.form,
          py: '3',
          px: '3',
          borderRadius: '14px',
        }}
      >
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form name="Contact" onSubmit={handleSubmit} method="post" action="/about-us/" data-netlify="true" data-netlify-honeypot="bot-field" sx={{ width: '100%' }}>
              <Field name="bot-field">
                {({ input, meta }) => (
                  <div hidden>
                    <label htmlFor="hide">Don’t fill this out:</label>
                    <input type="hidden" {...input} id="hide" />
                  </div>
                )}
              </Field>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['100%', '100%', '100%', '50% 50%'],
                }}
              >
                <Field name="name" validate={required}>
                  {({ input, meta }) => {
                    return (
                      <Flex
                        sx={{
                          variant: 'layout.contactFormField',
                          width: 'auto',
                        }}
                      >
                        <Flex sx={{ alignItems: 'center' }}>
                          <Styled.h6 as="label" sx={{ my: '1' }}>
                            What should we call you?*
                          </Styled.h6>
                          {meta.error && meta.touched && <CustomSpan>{meta.error}</CustomSpan>}
                        </Flex>
                        <CustomInput {...input} type="text" placeholder="Your name" />
                      </Flex>
                    )
                  }}
                </Field>

                <Field name="business">
                  {({ input, meta }) => (
                    <Flex
                      sx={{
                        variant: 'layout.contactFormField',
                        width: 'auto',
                      }}
                    >
                      <Flex sx={{ alignItems: 'center' }}>
                        <Styled.h6 as="label" sx={{ my: '1' }}>
                          Name of your business / company?
                        </Styled.h6>
                        {meta.error && meta.touched && <CustomSpan>{meta.error}</CustomSpan>}
                      </Flex>
                      <CustomInput {...input} type="text" placeholder="Vandelay Industries" />
                    </Flex>
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['100%', '50% 50%', '50% 50%', '50% 50%'],
                }}
              >
                {/* <Field name="phone" validate={composeValidators(mustBeNumber, minValue(8))}> */}
                <Field name="phone">
                  {({ input, meta }) => (
                    <Flex
                      sx={{
                        variant: 'layout.contactFormField',
                        width: 'auto',
                      }}
                    >
                      <Flex sx={{ alignItems: 'center' }}>
                        <Styled.h6 as="label" sx={{ my: '1' }}>
                          Contact Number
                        </Styled.h6>
                        {meta.error && meta.touched && <CustomSpan>{meta.error}</CustomSpan>}
                      </Flex>
                      <CustomInput {...input} type="text" placeholder="Phone number" />
                    </Flex>
                  )}
                </Field>
                <Field name="email" validate={required}>
                  {({ input, meta }) => (
                    <Flex
                      sx={{
                        variant: 'layout.contactFormField',
                        width: 'auto',
                      }}
                    >
                      <Flex sx={{ alignItems: 'center' }}>
                        <Styled.h6 as="label" sx={{ my: '1' }}>
                          Email*
                        </Styled.h6>
                        {meta.error && meta.touched && <CustomSpan>{meta.error}</CustomSpan>}
                      </Flex>
                      <CustomInput {...input} type="email" placeholder="Your email address" />
                    </Flex>
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['100%', '100%', '100%', '100%'],
                }}
              >
                <Field name="message" validate={required}>
                  {({ input, meta }) => (
                    <Flex
                      sx={{
                        variant: 'layout.contactFormField',
                        width: 'auto',
                      }}
                    >
                      <Flex sx={{ alignItems: 'center' }}>
                        <Styled.h6 as="label" sx={{ my: '1' }}>
                          Your Message*
                        </Styled.h6>
                        {meta.error && meta.touched && <CustomSpan>{meta.error}</CustomSpan>}
                      </Flex>
                      <CustomTextArea {...input} placeholder="Please let us know how can we help?" />
                    </Flex>
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['25%', '25%', '25%', '25%'],
                }}
              >
                <Flex sx={{ variant: 'layout.contactFormField' }}>
                  <ButtonAnimated type="submit" margin="0" variant="primary" scaleActive="1.1" label="Submit" disabled={submitting} />
                </Flex>
              </Box>
            </form>
          )}
        />
      </AnimatedFlex>
    </Fragment>
  )
}

export default ContactFormGrid
