/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { Waypoint } from 'react-waypoint'
import authorImage from '../../static/images/Andrew-Henry.png'
import BookACall from './bookACall'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const author = {
  author: true,
  authorName: 'Drew',
  authorImage: authorImage,
  authorTwitter: '@drewzmh',
  authorLinkedIn: 'andrew-henry1',
  authorLens: 'drewz.lens',
  authorEns: '',
  authorWebsite: '',
}
const ContactSideBar = () => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const addressFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 200 : 0,
  })
  return (
    <Fragment>
      <Waypoint
        bottomOffset="10%"
        onEnter={() => {
          if (!active) setActive(true)
        }}
      />
      <Box>
        <AnimatedFlex style={headingFade} sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 2, textAlign: 'center' }}>
            <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}>Send us an email</Styled.p>
          </Box>
        </AnimatedFlex>

        <AnimatedBox
          style={addressFade}
          sx={{
            width: ['100%', '100%', '100%'],
            // mx: 'auto',
            pt: [3, 3, 3, 3],
            pb: [4, 4, 4, 4],

            textAlign: 'center',
          }}
        >
          <a href={`mailto:hello@gramercystudios.com`} target="_blank" rel="noopener noreferrer" sx={{ variant: 'links.nav' }}>
            hello@gramercystudios.com
          </a>
        </AnimatedBox>
      </Box>
      <Box sx={{ mt: 4 }}>
        <AnimatedFlex style={headingFade} sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 2, textAlign: 'center', maxWidth: ['55%', '50%', '50%', '60%'] }}>
            <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}>Or book an introductory call with</Styled.p>
          </Box>
        </AnimatedFlex>

        <BookACall />
      </Box>
    </Fragment>
  )
}

export default ContactSideBar
