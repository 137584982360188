/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Blob from './blob'
import Container from './container'

const AnimatedBox = animated(Box)

const ContactHero = () => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Container>
          <Blob blob="workCaseStudies" blobHeight="100%" positionTop="-10%" positionLeft="47%" />
          <AnimatedBox
            style={headingFade}
            sx={{
              textAlign: 'center',
              mt: [4, 5, 6],
            }}
          >
            {/* <div sx={{ position: 'relative' }}> */}
            <Styled.h1 sx={{ variant: 'text.heading' }}>Get in touch</Styled.h1>
            {/* </div> */}
          </AnimatedBox>
          <AnimatedBox
            style={subtitleFade}
            sx={{
              px: [3, 4, 5],
              textAlign: 'center',
            }}
          >
            <Styled.h4 sx={{ variant: 'text.subheading' }}>Need help with your project? Want to join our team?</Styled.h4>
            <Styled.h4 sx={{ variant: 'text.subheading' }}>We'd love to hear from you!</Styled.h4>
          </AnimatedBox>
        </Container>
      </Box>
    </Fragment>
  )
}

export default ContactHero
