/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { keyframes } from '@emotion/core'

const AnimateWave = keyframes`
  0% {
      transform: scale(0.2,0.2) rotate(0deg);
    }

  20% {
      transform: scale(1,1) rotate(90deg);
    }
  50% {
      transform: scale(1,0.9) rotate(180deg);
    }
  60% {
      transform: scale(1,0.9) rotate(270deg);
    }
  100% {
    transform: scale(0.5, 0.5) rotate(360deg);
  }
`

const Blob = (props) => {
  const context = useThemeUI()
  const { theme } = context

  const { blob, blobHeight = '100%', positionTop = '0%', positionLeft = '0%' } = props

  const blobPath = (blob) => {
    if (blob === 'workCaseStudies') {
      const workCaseStudies = 'M48.7,-26.3C60.5,-7.8,65.4,16.4,56.2,31.9C46.9,47.5,23.5,54.3,-1.7,55.2C-26.9,56.2,-53.8,51.4,-67.3,33.4C-80.8,15.4,-80.9,-15.7,-67.5,-35.2C-54,-54.7,-27,-62.7,-4.3,-60.2C18.5,-57.7,37,-44.9,48.7,-26.3Z'

      return workCaseStudies
    }
    if (blob === 'workMoreProjects') {
      const workMoreProjects =
        'M38.1,-38.1C54.3,-31.7,76,-24.5,79.2,-12.9C82.5,-1.4,67.5,14.5,54.3,25.7C41.1,36.9,29.6,43.4,20.8,38.5C12,33.6,5.9,17.1,0.4,10.2C-5,3.3,-9.8,5.8,-13,5.1C-16.2,4.4,-17.9,0.5,-23.3,-10.3C-28.8,-21.1,-38,-38.7,-34.8,-47.4C-31.5,-56.1,-15.8,-55.9,-2.4,-53C10.9,-50.1,21.8,-44.5,38.1,-38.1Z'

      return workMoreProjects
    }
    if (blob === 'aboutHero') {
      const aboutHero =
        'M19.4,-32.5C24.2,-27.1,26.5,-20.1,36.7,-12.4C46.9,-4.8,65.1,3.6,70,14.2C74.8,24.7,66.4,37.5,53.4,38.5C40.4,39.5,22.8,28.7,11.9,24.8C0.9,20.9,-3.5,23.9,-10.1,25.8C-16.6,27.7,-25.4,28.7,-35.8,25.9C-46.2,23.1,-58.3,16.6,-66.7,5.2C-75.2,-6.2,-80,-22.4,-69.3,-25.8C-58.7,-29.2,-32.6,-19.7,-18.5,-20.9C-4.4,-22,-2.2,-33.9,2.6,-37.9C7.3,-41.8,14.6,-37.9,19.4,-32.5Z'

      return aboutHero
    }
  }

  const blobColor = theme.colors.blob

  return (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        style={{
          position: 'absolute',
          top: positionTop,
          left: positionLeft,
          zIndex: '-1',
          height: blobHeight, // 100% for mobile devices, otherwise wave shrinks
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
        sx={{
          '@media (prefers-reduced-motion: no-preference)': { animation: `${AnimateWave} 25000ms cubic-bezier(.67,.24,.36,1) forwards infinite alternate;` },
          animation: `undefined`,
        }}
      >
        <path fill={blobColor} fillOpacity="1" d={blobPath(blob)} transform="translate(100 100)"></path>
      </svg>
    </Fragment>
  )
}

export default Blob
