import React from 'react'
import Contact from '../components/contact'


const ContactPageTemplate = () => {

  return <Contact />
}

export default ContactPageTemplate
