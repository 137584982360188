/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import { Fragment } from 'react'
import ContactFormGrid from './contactFormGrid'
import ContactSideBar from './contactSideBar'

const ContactBody = () => {
  return (
    <Fragment>
      <Box
        sx={{
          maxWidth: 'container',
          mx: 'auto',
          pt: 3,
          pl: ['2', '2', '4', '4'],
          pr: ['2', '2', '0', '0'],
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['100%', '100%', '100%', '650px 350px'],
            gridGap: ['0px', '0px', '0px', '20px'],
            justifyItems: 'space-evenly',
            alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
          }}
        >
          <Flex sx={{ flexDirection: 'column', variant: 'layout.contactForm', width: ['auto', 'auto', 'auto', 'auto'] }}>
            <ContactFormGrid />
          </Flex>
          <Flex sx={{ flexDirection: 'column', variant: 'layout.contactSideBar' }}>
            <ContactSideBar />
          </Flex>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ContactBody
