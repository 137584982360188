/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { Waypoint } from 'react-waypoint'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const ContactFooter = () => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const context = useThemeUI()
  const { theme } = context
  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const addressFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 200 : 0,
  })

  return (
    <Fragment>
      <Waypoint
        bottomOffset="10%"
        onEnter={() => {
          if (!active) setActive(true)
        }}
      />
      {/* PERTH Office */}
      <Box sx={{ mt: 4, mb: 0, px: [1, 4, 4] }}>
        <AnimatedFlex style={headingFade} sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
          <Box sx={{ mx: 2, textAlign: 'center' }}>
            <Styled.p sx={{ fontSize: 2, variant: 'text.caps', color: 'headingSecondary' }}>Visit</Styled.p>
          </Box>
          <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
        </AnimatedFlex>

        <AnimatedBox
          style={addressFade}
          sx={{
            width: ['100%', '75%', '75%'],
            mx: 'auto',
            pt: [3, 4, 4],
            pb: [3, 3, 3],
            px: [1, 5, 6],
            textAlign: 'center',
          }}
        >
          <a href="https://goo.gl/maps/zzjbvZRAFZu37Kuo7">
            <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>
              <span
                sx={{
                  color: theme.colors.headingSecondary,
                  position: 'relative',
                  display: 'inline-block',
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    // zIndex: '-1',
                    top: '60%',
                    left: '-0.1em',
                    right: '-0.1em',
                    bottom: '0',
                    backgroundColor: theme.colors.externalLinkTo,
                    opacity: 0.4,
                    transition: 'top 700ms ease-out',
                  },
                  ':hover:after': {
                    opacity: 0.4,
                    top: '5%',
                  },
                }}
              >
                1/18 Olive Street
              </span>
            </Styled.p>
          </a>
          <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>Subiaco, WA 6008</Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>Australia</Styled.p>
        </AnimatedBox>
      </Box>
      {/* BRISBANE Office */}
      <Box sx={{ mt: 0, mb: 4, px: [1, 4, 4] }}>
        <AnimatedBox
          style={addressFade}
          sx={{
            width: ['100%', '75%', '75%'],
            mx: 'auto',
            pt: [3, 4, 4],
            pb: [3, 3, 3],
            px: [1, 5, 6],
            textAlign: 'center',
          }}
        >
          <a href="https://goo.gl/maps/AR2D927HWLFg4d517">
            <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>
              <span
                sx={{
                  color: theme.colors.headingSecondary,
                  position: 'relative',
                  display: 'inline-block',
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    // zIndex: '-1',
                    top: '60%',
                    left: '-0.1em',
                    right: '-0.1em',
                    bottom: '0',
                    backgroundColor: theme.colors.externalLinkTo,
                    opacity: 0.4,
                    transition: 'top 700ms ease-out',
                  },
                  ':hover:after': {
                    opacity: 0.4,
                    top: '5%',
                  },
                }}
              >
                Brisbane
              </span>
            </Styled.p>
          </a>
          <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>(Office opening soon)</Styled.p>
          <Styled.p sx={{ fontSize: '3', variant: 'text.contactFooter', mb: 0, color: 'text' }}>Australia</Styled.p>
        </AnimatedBox>
      </Box>
    </Fragment>
  )
}

export default ContactFooter
